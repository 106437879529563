import React from "react"

function DebtConsolidationIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="49"
      fill="none"
      viewBox="0 0 48 49"
    >
      <g clip-path="url(#clip0)">
        <path
          fill="#F66B02"
          d="M27.843 14.273a.94.94 0 10.001 1.88.94.94 0 000-1.88z"
        ></path>
        <path
          fill="#fff"
          d="M22.149 32.62a.94.94 0 00-1.329-1.33.94.94 0 001.329 1.329z"
        ></path>
        <path
          fill="#fff"
          d="M10.864 47.818a.939.939 0 001.325.007l4.553-4.485a2.838 2.838 0 00.668-2.914l.98-.945a2.8 2.8 0 011.955-.79H32.83c2.215 0 4.308-.85 5.895-2.395.065-.063-.496.598 8.517-10.174a3.761 3.761 0 00-.446-5.294 3.766 3.766 0 00-5.27.419l-5.541 5.695a3.774 3.774 0 00-2.92-1.398H22.592a12.114 12.114 0 00-4.702-.939c-4.52 0-8.47 2.087-10.59 6.005-.893-.17-1.834.1-2.512.778l-4.467 4.48a.939.939 0 00-.001 1.325l10.544 10.625zm7.025-21.335c1.439 0 2.83.29 4.134.86a.938.938 0 00.377.08h10.664c1.018 0 1.878.83 1.878 1.878a1.88 1.88 0 01-1.878 1.878h-7.662a.94.94 0 000 1.878h7.662a3.76 3.76 0 003.723-4.247c5.36-5.51 6.116-6.285 6.149-6.323.669-.79 1.857-.89 2.648-.224.79.669.89 1.856.22 2.65l-8.423 10.07a6.539 6.539 0 01-4.552 1.83H20.345a4.669 4.669 0 00-3.26 1.317l-.798.77-7.357-7.356c1.72-3.226 4.946-5.06 8.96-5.06zM6.117 32.715a.937.937 0 011.163-.132c.144.087 8.176 8.14 8.225 8.206a.943.943 0 01-.086 1.218l-3.881 3.824-9.227-9.298 3.806-3.818z"
        ></path>
        <path
          fill="#F66B02"
          d="M14.697 19.908h22.537a2.82 2.82 0 002.818-2.817V2.817A2.82 2.82 0 0037.234 0H14.697a2.82 2.82 0 00-2.818 2.817v14.274a2.82 2.82 0 002.818 2.817zM38.173 7.512H13.758V5.635h24.415v1.877zm-.939 10.518H14.697a.94.94 0 01-.94-.939v-7.7h24.416v7.7a.94.94 0 01-.939.94zM14.697 1.878h22.537a.94.94 0 01.94.94v.938H13.757v-.939a.94.94 0 01.939-.939z"
        ></path>
        <path
          fill="#F66B02"
          d="M35.356 14.273H31.6a.94.94 0 000 1.879h3.756a.94.94 0 100-1.879z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H48V48.094H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export { DebtConsolidationIcon }
