import React from "react"

function QuotesIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="34"
      fill="none"
      viewBox="0 0 44 34"
    >
      <path
        fill="#121212"
        fillOpacity="0.1"
        d="M.204 33.888h10.224L20.076.048h-8.208L.204 33.888zm23.76 0h10.224L43.836.048h-8.208l-11.664 33.84z"
      ></path>
    </svg>
  )
}

export { QuotesIcon }
