import {customCloudinaryImage} from '../common/utils'

export type ProductPageType = {
  id: string
  slug: string
  name: string
  meta: {
    title: string
    description: string
    keywords: string
  }
  landing: {
    graphics_image: string
    content: string
  }
  features: {
    title: string
    content: string
  }[]
  eligibility: {
    content: string[]
  } & (
    | {
        type: 'list'
        data: {
          title: string
        }[]
      }
    | {
        type: 'grid'
        data: {
          title: string
          content: string[]
        }[]
      }
  )
  apply_procedure: {
    content: string[]
    data: {
      title: string
      content: string[]
    }[]
  }
}

const ProductsAvailable = [
  'business_loan',
  'two_wheeler_loan',
  'personal_loan',
  'used_car_loan',
  'marriage_loan',
  'laptop_loan',
  'consumer_durable_loan',
  'medical_loan',
  'debit_consolidation_loan',
  'home_renovation_loan',
  'mobile_loan',
  'travel_loan',
] as const

type ProductsAvailable = typeof ProductsAvailable[number]

type IProducts = Record<ProductsAvailable, ProductPageType>

const features = [
  {
    title: 'Apply Instantly',
    content: `Applying for a Used Car Loan is easy with Zavron. Just fill the form and we’ll direct you forward.`,
  },
  {
    title: 'High Disbursal Amount',
    content:
      'If you fit into the eligibility criteria, then you can apply for a loan of upto 10 lacs.',
  },
  {
    title: 'Flexible Repayment Options',
    content:
      'Our flexible repayment options and competitive interest rates help you repay loans within a period of 60 months.',
  },
  {
    title: 'Hassle-free Documentation',
    content:
      'We assist you with Used Car Loans with easy and hassle-free documentations.',
  },
]

const ApplyProcedureData = [
  {
    title: 'Fill Online Application',
    content: [`On our website, fill the application form for Used Car Loan.`],
  },
  {
    title: 'Submit Documents',
    content: [
      `We’ll take your request and arrange an appointment with a relationship representative to collect necessary documents.`,
    ],
  },
  {
    title: 'Verification Process',
    content: [
      'After all the paperwork, your documents will be verified and you may be approached for a personal discussion.',
    ],
  },
  {
    title: 'Quick Approval & Disbursal',
    content: [
      'After a quick verification, we’ll approve your loan and the amount will be disbursed in 2 days’ time.',
    ],
  },
]

const EligibilityData = [
  {
    title: 'Your age is between 21 years and 60 years.',
  },
  {
    title: 'You are employed in a private, public or government sector',
  },
  {
    title: 'You have 2 years of work experience',
  },
  {
    title: 'You have completed 6 months’ period with your current employer',
  },
  {
    title: 'You are earning at least INR 1,80,000 p.a',
  },
]

const makeProductData = (
  product: Partial<ProductPageType>,
): ProductPageType => {
  return {
    features,
    eligibility: {
      type: 'list',
      content: [
        `Share your personal and work details with us to know your loan`,
        `eligibility. You will be eligible if,`,
      ],
      data: EligibilityData,
    },
    apply_procedure: {
      content: [
        `You can apply for a ${product.name} at Zavron in a simple and hassle-free
        method. We are at your fingertips with ZinCash, our mobile app, or
        you can apply on our website and get loans quickly.`,
      ],
      data: ApplyProcedureData,
    },
    ...product,
  } as ProductPageType
}

const getCloudinaryImage = (url: string) =>
  customCloudinaryImage(url, 'c_scale,f_auto,w_700/')

export const PRODUCTS: IProducts = {
  business_loan: {
    id: 'business_loan',
    slug: 'business-loan',
    name: 'Business Loan',
    meta: {
      title: 'Business Loan | Apply for Low Interest Business Loans Online',
      description:
        'Zavron Finserv offers secured small  business loan at lowest interest rates to streamline all your financial needs. Visit our website & apply for business loans',
      keywords:
        'Business Loan, small business loans, financing for small businesses, sme loan, online business loan, apply for business loan, sme business loan',
    },
    landing: {
      graphics_image:
        'https://res.cloudinary.com/dhoiqmk4x/image/upload/c_scale,f_auto,w_700/v1597798964/Group_184_fxsxzw.png',
      content: `Struggling to get a || business loan | /products/business-loan || quickly?
      Zavron is here at your aid. Your business expansion is
      not a far-fetched dream anymore. If you are planning to take
      your business a notch-up and seeking financial help, we are here
      for you. We help partnership firms, proprietorship, and private
      limited companies. Avoid complex procedures and get Business
      Loans easily at Zavron.`,
    },
    features,
    eligibility: {
      type: 'list',
      content: [
        `Just share a few details with us to know your eligibility for a
        Business Loan.`,
        `Your eligibility depends on the following factors`,
      ],
      data: [
        {
          title: 'Minimum age must be 24 years',
        },
        {
          title: 'KYC documents (Aadhar Card, PAN card, etc.)',
        },
        {
          title: 'Income Tax Returns of last 3 years',
        },
        {
          title: 'Bank statements of 12 months',
        },
        {
          title: 'Vintage Proof (Trade Licence, Cash Flow Statement, etc.)',
        },
      ],
    },
    apply_procedure: {
      content: [
        `You can apply for a Business Loan at Zavron in a simple and
      hassle-free method. You can apply on our website and get loans
      quickly.`,
      ],
      data: ApplyProcedureData,
    },
  },
  two_wheeler_loan: {
    id: 'two_wheeler_loan',
    slug: 'two-wheeler-loan',
    name: 'Two-Wheeler Loan',
    meta: {
      title: 'Two Wheeler Loan | Bike Loan Online | Lowest Interest Rate',
      description:
        'Zavron Finserv give you the best two wheeler loan interest rate and flexible repayment tenure. Take advantage of our additional offer such as low down-payment',
      keywords: `two wheeler loan, bike loan, bike loan interest rate, two wheeler loan online approval, two wheeler finance, second hand bike loan`,
    },

    landing: {
      graphics_image:
        'https://res.cloudinary.com/dhoiqmk4x/image/upload/c_scale,f_auto,w_700/v1597798964/Group_186_lwnajg.png',
      content: `Your low funds for a new bike shouldn’t stop you.
      Zavron || Two-wheeler Loan | /products/two-wheeler-loan || will help you out.
      Our easy and flexible Two-wheeler Loan options will lend you a helping hand. You can avail these loans at competitive interest rates and flexible repayments.`,
    },
    features,
    eligibility: {
      type: 'list',
      content: [
        `Share your personal and work details with us to know your loan`,
        `eligibility. The eligibility criteria is as follows:`,
      ],
      data: [
        {
          title: 'Minimum age must be 23 years',
        },
        {
          title: 'KYC Documents (Aadhaar Card, PAN Card, etc.)',
        },
        {
          title: 'Income proof',
        },
        {
          title: "Last 3 months' salary slips",
        },
        {
          title: 'Bank statement of last 12 months',
        },
        {
          title: 'Vehicle registration documents',
        },
      ],
    },
    apply_procedure: {
      content: [
        `You can apply for a Two-wheeler Loan at Zavron in a simple and
        hassle-free method. You can apply on our website and get loans
        quickly.`,
      ],
      data: ApplyProcedureData,
    },
  },
  personal_loan: {
    id: 'personal_loan',
    slug: 'personal-loan',
    name: 'Personal Loan',
    meta: {
      title: 'Personal Loan | Instant Personal Loan | Nagpur | Maharashtra',
      description:
        'Get a personal loan from private finance in Nagpur Zavron Finserv provides personal loan for salaried and self-employed apply now &amp; get instant approval',
      keywords: `personal loan, apply for personal loan, best personal loan, Instant Personal Loan, Online Personal Loan, personal loan interest rates, easy personal Loan`,
    },

    landing: {
      graphics_image:
        'https://res.cloudinary.com/dhoiqmk4x/image/upload/c_scale,f_auto,w_700/v1597798964/Group_183_spea91.png',
      content: `
      Zavron’s safe and easy || Personal Loans | /products/personal-loan ||
      cater to your urgent financial needs. Whether it’s a medical
      emergency or just a fun vacation, a plan to renovate home or
      preparation for wedding functions, we take care of all your
      personal needs. At Zavron, we aim to bring your dreams closer to
      reality and help you take a step ahead to fulfill your wishes.
      We give you a loan from INR 50k to INR 15 Lacs at 16-36% interest rate per annum.`,
    },

    features,
    eligibility: {
      type: 'grid',
      content: [
        `Just share your personal and work details with us and we’ll get back`,
        `to you. Your eligibility depends on the following factors.`,
      ],
      data: [
        {
          title: 'Personal Loan for Salaried Individuals',
          content: [
            '- Minimum age must be 21 years',
            '- KYC documents (Aadhaar Card, PAN Card, etc.)',
            '- 3 months’ salary slips',
            '- Bank statements of last 6 months',
          ],
        },
        {
          title: 'Personal Loan for Self- Employed Individuals',
          content: [
            `- Minimum age must be 23 years`,
            '- KYC documents (Aadhaar Card, PAN Card, etc.)',
            '- Income Tax Return of last 3 years',
            '- Bank statements of last 12 months',
          ],
        },
      ],
    },
    apply_procedure: {
      content: [
        `Applying for a Personal Loan is now simple and hassle-free. Here is what you need to do to get a loan on our website.`,
      ],
      data: ApplyProcedureData,
    },
  },
  used_car_loan: {
    id: 'used_car_loan',
    slug: 'used-car-loan',
    name: 'Used Car Loan',
    meta: {
      title: 'Used Car Loan | Lowest Interest Rate | Zavron Finserv',
      description:
        'Apply for Zavron Finserv Used Car Loan online and avail exclusive benefits of attractive and Lowest interest rates along with EMI calculator. Apply Now!',
      keywords: `used car loan, used car finance, vehicle loan, second hand car loan, interest rates on car loans, vehicle loan interest rates, car in finance`,
    },

    landing: {
      graphics_image:
        'https://res.cloudinary.com/dhoiqmk4x/image/upload/c_scale,f_auto,w_700/v1597798973/Group_185_of3bwq.png',
      content: `Don’t hold back from opting for a || Used Car loan | /products/used-car-loan ||
      if you are running short of funds to buy a second-hand car.
      We have interesting loan options for you. Zavron believes you
      deserve the car of your dreams and lack of money shouldn’t hold
      you back. Know how our Used Car Loan works.`,
    },

    features,
    eligibility: {
      type: 'list',
      content: [
        `Share your personal and work details with us to know your loan`,
        `eligibility. You will be eligible if,`,
      ],
      data: [
        {
          title: 'Minimum age must be 23 years',
        },
        {
          title: 'KYC Documents (Aadhaar Card, PAN Card, etc.)',
        },
        {
          title: 'Income proof',
        },
        {
          title: 'Bank statement of last 12 months',
        },
        {
          title: 'Vehicle registration documents',
        },
        {
          title: 'Vehicle should not be more than 7 years old',
        },
      ],
    },
    apply_procedure: {
      content: [
        `You can apply for a Used Car Loan at Zavron in a simple and
        hassle-free method. You can apply on our website and get loans
        quickly.`,
      ],
      data: ApplyProcedureData,
    },
  },
  marriage_loan: makeProductData({
    id: 'marriage_loan',
    slug: 'marriage-loan',
    name: 'Marriage Loan',
    meta: {
      title: 'Marriage Loan | Instant Personal Loan | Low Interest Rate',
      description:
        'Quick and easy marriage loan at an attractive rate of interest from a best personal loan provider for instant lending support to plan your dream wedding',
      keywords: ``,
    },
    landing: {
      graphics_image: getCloudinaryImage(
        'https://res.cloudinary.com/dhoiqmk4x/image/upload/v1612334709/Website/products/zavron_marriage_loan_ytelpt.png',
      ),
      content: `Weddings are one of the special ceremonies in everyone’s life. Most
      likely you would need this important day to be noteworthy. Not
      every person is brought into the world with a golden spoon!
      Anyway, less funds should not affect your wedding plans to make it
      so special.\n
      You are looking for funds as you or your family member is getting
      married soon and you are running short with funds. Here is nothing
      to worry about; we are here to help you with an easy and instant
      Marriage loan, which you can get in a jiffy. You will get funds as soon
      as you apply with Zavron Finserv. Here’s no worry at all as we have a
      convenient way of applying for a loan with fast disbursement.
      `,
    },
  }),
  laptop_loan: makeProductData({
    id: 'laptop_loan',
    slug: 'laptop-loan',
    name: 'Laptop Loan',
    meta: {
      title: 'Laptop Loan | Personal Loan for Laptop | Mobile',
      description: `Get an instant personal loan to purchase a brand-new laptop at a modest rate of interest from a
      personal finance company in India with a hassle free loan apply process`,
      keywords: ``,
    },
    landing: {
      graphics_image: getCloudinaryImage(
        'https://res.cloudinary.com/dhoiqmk4x/image/upload/v1612334694/Website/products/zavron_laptop_loan_uhsua6.png',
      ),
      content: `Is your old laptop slowing down your productivity? You've been
      thinking off swapping your old laptop for a premium new model,
      but worried how you will be able to buy a top-quality laptop in case
      you are lacking funds. Every time you have planned to get a new
      laptop on loan but sit back after scrolling so many laptops. Stop
      scrolling several models.

      Zavron Finserv brings you an opportunity to switch to a new laptop
      from any brands which accompany new-age details. If you are a
      salaried professional you can apply for a loan with minimum
      documents. Apply now for a Laptop Loan to know your eligibility.
      `,
    },
  }),
  consumer_durable_loan: makeProductData({
    id: 'consumer_durable_loan',
    slug: 'consumer-durable-loan',
    name: 'Consumer Durable Loan',
    meta: {
      title: 'Consumer Durable Loan | Personal Loan | Renovation Loans',
      description: `Avail a personal loan to purchase consumer durable at the lowest interest rate with minimum
      documents and instant loan approval`,
      keywords: ``,
    },
    landing: {
      graphics_image: getCloudinaryImage(
        'https://res.cloudinary.com/dhoiqmk4x/image/upload/v1612334727/Website/products/zavron_consumer_durable_loan_hrg7mw.png',
      ),
      content: `A beautiful home needs different types of amenities to make your
      family members feel comfortable. Purchase items like household
      appliances, for example, televisions, fridges, climate control
      systems, Washing Machines, microwaves, home theaters, PCs, and
      significantly more by availing a personal loan as Consumer Durable
      Loan from Zavron Finserv.

      Our Consumer Durable Loan is perhaps the most ideal choice which
      assists you to finance your purchase of consumer durables. You can
      plan or purchase these items by availing easy EMI for your personal
      loan. Once you get apply for a loan with us it gets disbursed within a
      short period of time so that you will not miss any available deal of
      your choice.
      `,
    },
  }),
  medical_loan: makeProductData({
    id: 'medical_loan',
    slug: 'medical-loan',
    name: 'Medical Loan',
    meta: {
      title: 'Medical Loan | Medical Emergency Loan | Personal Finance | NBFC',
      description: `Get a quick personal loan for medical emergency at a low rate of interest from a private finance
      company in India to cover all your medical expenses`,
      keywords: ``,
    },
    landing: {
      graphics_image: getCloudinaryImage(
        'https://res.cloudinary.com/dhoiqmk4x/image/upload/v1612334708/Website/products/zavron_medical_loan_xrtppn.png',
      ),
      content: `Medical emergencies are something which leads to mental stress.
      Nobody wishes to get stuck in medical emergencies, but still, they
      happen and such emergencies can cause an immediate
      requirement of funds. Medical emergencies can be more expensive;
      it exceeds all your savings without pre-notice.

      During medical emergencies moral support is required to the family
      members whereas requirement of immediate funds may lead to
      more stress. Don’t worry If you are trying to get an instant personal
      loan, we can help you, ask us without wasting your time. With our
      medical loan service, you can get immediate financial help by
      choosing the best medical loan help without worrying about
      treatment bills.
      `,
    },
  }),
  debit_consolidation_loan: makeProductData({
    id: 'debit_consolidation_loan',
    slug: 'debit-consolidation-loan',
    name: 'Debit Consolidation Loan',
    meta: {
      title: 'Debt Consolidation Loan | Personal Loans | Low Interest Rate',
      description: `Apply now for a debt consolidation loan from private finance at a low rate of interest with hassle
      free documents and quick disbursal of personal loans`,
      keywords: ``,
    },
    landing: {
      graphics_image: getCloudinaryImage(
        'https://res.cloudinary.com/dhoiqmk4x/image/upload/v1612334705/Website/products/zavron_debt_consolidation_loan_vwgabr.png',
      ),
      content: `Who wants a load of high debt to carry in, circumstances have made
      you consume whole limits of your Credit Cards and now willing to
      consolidate those debts? Until unless such debts are cleared you
      are not able to have sound sleep or these debts are affecting your
      routine house-hold expenses.

      You don’t have any choice except consolidating those debts. Zavron
      Finserv brings you to choose an option of Urgent Personal loan as
      Debt Consolidation loan and you will be able to pay-off your debts.
      You Will be able to pay it back in easy EMIs and that too at attractive
      interest rates`,
    },
  }),
  home_renovation_loan: makeProductData({
    id: 'home_renovation_loan',
    slug: 'home-renovation-loan',
    name: 'Home Renovation Loan',
    meta: {
      title: 'Home Renovation Loan | Instant Personal Loan | Private Finance',
      description: `Apply for an instant personal loan to renovate your home and get the lowest rate of interest with
      minimal documents and easy repayment tenure to design your home`,
      keywords: ``,
    },
    landing: {
      graphics_image: getCloudinaryImage(
        'https://res.cloudinary.com/dhoiqmk4x/image/upload/v1612334712/Website/products/zavron_home_renovation_loan_zchifa.png',
      ),
      content: `Home is your appearance; you have always thought about be able
      to tidy up your home and redesign it as per your personality. Either
      you want to purchase new furniture, upgrade your gadgets or
      kitchen equipment, all will require a lot of money to spend in.
      Whereas due to low fund it becomes impossible to do it all at a
      time.

      Good news behind it is with the help of Zavron Finserv's Home
      Renovation Loans you can completely change your current home to
      a modern plan and a more open to living space. Upgrade your
      accessories, purchase new curtains or update your modular kitchen.
      All you need is to apply for a personal loan as Home Renovation
      Loan.`,
    },
  }),
  mobile_loan: makeProductData({
    id: 'mobile_loan',
    slug: 'mobile-loan',
    name: 'Mobile Loan',
    meta: {
      title: 'Mobile Loan | Instant Personal Finance | Finance Company',
      description: `Get quick personal loans to purchase a new mobile phone at the lowest rate of interest from a
      personal finance company in India with hassle free documents`,
      keywords: ``,
    },
    landing: {
      graphics_image: getCloudinaryImage(
        'https://res.cloudinary.com/dhoiqmk4x/image/upload/v1612334721/Website/products/zavron_mobile_loan_fu0mqd.png',
      ),
      content: `Wow! A new mobile phone from your preferred brand has just
      launched with amazing features, is out of reach just because itis
      launched when you don’t have a planned budget to purchase a new
      Mobile phone. If you don’t want to miss the opportunity to own it,
      plan it now to geta quick and easy Mobile Loan.

      We have interesting loan options on attractive interest rates for you.
      Zavron Finserv believes you deserve a mobile phone of your choice.
      Lack of money shouldn’t hold you back. Want to know more about
      how it works. Apply now for a personal loan to purchase a new
      Mobile.
      `,
    },
  }),
  travel_loan: makeProductData({
    id: 'travel_loan',
    slug: 'travel-loan',
    name: 'Travel Loan',
    meta: {
      title: 'Travel Loan | Personal Loan for Vacation | Family Vacation Loan',
      description: `Apply for a travel loan from Zavron Finserv at lowest interest rate with instant approval on
      personal finance to enjoy holidays with your family`,
      keywords: ``,
    },
    landing: {
      graphics_image: getCloudinaryImage(
        'https://res.cloudinary.com/dhoiqmk4x/image/upload/v1612334721/Website/products/zavron_travel_loan_ydruzz.png',
      ),
      content: `Does your family complain about vacation, you haven’t taken them
      for a long time? We know your answer is yes! There are a lot of
      unseen places which makes you or your family members just to be
      there for a long weekend. Just because of lack of funds you were
      not able to fulfill your family’s dreams of spending a quality time by
      travelling distances.

      Pamper your loved ones by planning family vacations with easy
      Travel loans by Zavron Finserv. With no worries you can plan it at the
      earliest possible. You are just a click away to avail this interesting
      Travel Loan option for you. Check your documents, apply as soon as
      you can and sooner you will be on a family trip to your loved
      destination.`,
    },
  }),
}
