// LaptopLoanIcon
import React from "react"

function LaptopLoanIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      fill="none"
      viewBox="0 0 48 48"
    >
      <g clip-path="url(#clip0)">
        <path
          fill="#F66B02"
          d="M41.595 9.58H13.303a.77.77 0 100 1.539h27.523v20.32H7.174V11.12h3.495a.77.77 0 100-1.539H6.405a.77.77 0 00-.77.77v21.858c0 .425.345.77.77.77h35.19a.77.77 0 00.77-.77V10.35a.77.77 0 00-.77-.769z"
        ></path>
        <path
          fill="#fff"
          d="M47.23 35.126h-1.178V17.674a.77.77 0 10-1.538 0v17.452H3.486V9.3A1.87 1.87 0 015.353 7.43h37.294a1.87 1.87 0 011.867 1.868v5.741a.77.77 0 101.538 0V9.3a3.41 3.41 0 00-3.405-3.406H5.353a3.41 3.41 0 00-3.405 3.406v25.827H.769a.77.77 0 00-.769.77v2.414a3.802 3.802 0 003.798 3.798h2.407a.77.77 0 100-1.539H3.798a2.262 2.262 0 01-2.26-2.259v-1.645h17.213a3.004 3.004 0 002.976 2.63h4.546a3.004 3.004 0 002.977-2.63h17.212v1.645a2.262 2.262 0 01-2.26 2.26H8.77a.77.77 0 100 1.538h35.433A3.802 3.802 0 0048 38.31v-2.415a.77.77 0 00-.77-.769zm-20.956 2.63h-4.547a1.464 1.464 0 01-1.414-1.09h7.374a1.464 1.464 0 01-1.414 1.09z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H48V48H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export { LaptopLoanIcon }
