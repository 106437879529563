// HomeRenovationIcon
import React from "react"

function HomeRenovationIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      fill="none"
      viewBox="0 0 48 48"
    >
      <path
        fill="#fff"
        d="M40.007 26.389a.8.8 0 00-.8.8v18.409a.8.8 0 01-.801.8h-8.004V34.392a2.401 2.401 0 00-2.401-2.4h-8.004a2.401 2.401 0 00-2.401 2.4v12.006H9.592a.8.8 0 01-.8-.8V27.189a.8.8 0 00-1.601 0v18.409a2.401 2.401 0 002.401 2.401h28.814a2.401 2.401 0 002.401-2.401V27.189a.8.8 0 00-.8-.8zM28.8 46.399h-9.604V34.391a.8.8 0 01.8-.8H28a.8.8 0 01.8.8v12.006zM45.051 20.618L25.704.702a2.424 2.424 0 00-3.41 0L2.948 20.618a2.472 2.472 0 00-.499 2.692 2.368 2.368 0 002.203 1.479h.273a2.38 2.38 0 001.553-.597c.057-.037.11-.08.16-.126L24 6.184 41.37 24.076c.047.043.097.083.15.119.429.377.98.588 1.55.594h.274a2.368 2.368 0 002.205-1.479 2.472 2.472 0 00-.498-2.692zm-.975 2.07a.792.792 0 01-.73.5h-.275a.773.773 0 01-.56-.24c-.006-.007-.014-.008-.02-.014-.005-.006-.011-.014-.018-.02l-17.9-18.437-.01-.006-.004-.012a.729.729 0 00-.124-.08.762.762 0 00-.134-.086.745.745 0 00-.15-.032.433.433 0 00-.299 0 .751.751 0 00-.15.032.777.777 0 00-.135.086.733.733 0 00-.124.08l-.006.01-.01.006-17.904 18.44c-.007.005-.01.013-.017.018-.007.006-.015.008-.021.015a.773.773 0 01-.56.24h-.272a.793.793 0 01-.731-.5.87.87 0 01.172-.955h-.002L23.44 1.818a.786.786 0 011.115 0l19.35 19.915a.87.87 0 01.172.955z"
      ></path>
      <path
        fill="#F66B02"
        d="M27.2 27.991a2.401 2.401 0 002.402-2.401v-5.603a2.401 2.401 0 00-2.401-2.401h-6.404a2.401 2.401 0 00-2.4 2.401v5.603a2.401 2.401 0 002.4 2.401h6.404zm-7.203-2.401v-5.603a.8.8 0 01.8-.8h6.404a.8.8 0 01.8.8v5.603a.8.8 0 01-.8.8h-6.404a.8.8 0 01-.8-.8z"
      ></path>
      <path
        fill="#fff"
        d="M21.598 39.195h-.8a.8.8 0 100 1.601h.8a.8.8 0 100-1.6z"
      ></path>
    </svg>
  )
}

export { HomeRenovationIcon }
