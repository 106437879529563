import React from "react"

function ConsumerDurableIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      fill="none"
      viewBox="0 0 48 48"
    >
      <path
        fill="#F66B02"
        d="M13 16a1 1 0 00-1 1v3H8v-3a1 1 0 00-2 0v8a1 1 0 002 0v-3h4v3a1 1 0 002 0v-8a1 1 0 00-1-1zM21 16h-4a1 1 0 00-1 1v8a1 1 0 001 1h4c1.654 0 3-1.346 3-3v-4c0-1.654-1.346-3-3-3zm1 7a1 1 0 01-1 1h-3v-6h3a1 1 0 011 1v4z"
      ></path>
      <path
        fill="#fff"
        d="M45 4H3C1.346 4 0 5.346 0 7v28c0 1.654 1.346 3 3 3h17v2.154l-11.164 1.86a1 1 0 00.328 1.972L21.08 42h5.838l11.916 1.986a.994.994 0 001.15-.822.999.999 0 00-.822-1.15L28 40.154V38h17c1.654 0 3-1.346 3-3V7c0-1.654-1.346-3-3-3zM26 40h-4v-2h4v2zm20-5a1 1 0 01-1 1H3a1 1 0 01-1-1V7a1 1 0 011-1h42a1 1 0 011 1v28z"
      ></path>
      <path
        fill="#F66B02"
        d="M32 16h-6a1 1 0 000 2h2v7a1 1 0 002 0v-7h2a1 1 0 000-2zM41.352 16.064a.998.998 0 00-1.288.584L38 22.152l-2.064-5.504a1 1 0 00-1.872.704l3 8a1 1 0 001.872 0l3-8a1 1 0 00-.584-1.288z"
      ></path>
    </svg>
  )
}

export { ConsumerDurableIcon }
