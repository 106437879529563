// MobileLoanIcon
import React from "react"

function MobileLoanIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      fill="none"
      viewBox="0 0 48 48"
    >
      <g clip-path="url(#clip0)">
        <path
          fill="#F66B02"
          d="M36 28c.372 0 .702-.13 1-.306.298.176.628.306 1 .306a2 2 0 000-4c-.372 0-.702.13-1 .306-.298-.176-.628-.306-1-.306a2 2 0 000 4z"
        ></path>
        <path
          fill="#fff"
          d="M27.04 4h-.02a.992.992 0 00-.99 1c0 .552.458 1 1.01 1a1 1 0 000-2zM21 4h-4a1 1 0 000 2h4a1 1 0 000-2z"
        ></path>
        <path
          fill="#fff"
          d="M33 34a1 1 0 00-1 1v1H6V10h26v3a1 1 0 002 0V7c0-3.86-3.14-7-7-7H11C7.14 0 4 3.14 4 7v34c0 3.86 3.14 7 7 7h16c3.86 0 7-3.14 7-7v-6a1 1 0 00-1-1zM6 7c0-2.756 2.244-5 5-5h16c2.756 0 5 2.244 5 5v1H6V7zm26 34c0 2.756-2.244 5-5 5H11c-2.756 0-5-2.244-5-5v-3h26v3z"
        ></path>
        <path
          fill="#F66B02"
          d="M41 16H25c-1.654 0-3 1.346-3 3v10c0 1.654 1.346 3 3 3h16c1.654 0 3-1.346 3-3V19c0-1.654-1.346-3-3-3zm1 13a1 1 0 01-1 1H25a1 1 0 01-1-1v-7h18v7zm0-9H24v-1a1 1 0 011-1h16a1 1 0 011 1v1z"
        ></path>
        <path fill="#fff" d="M19 44a2 2 0 10-.001-4.001 2 2 0 00.001 4z"></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H48V48H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export { MobileLoanIcon }
