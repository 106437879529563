import React from "react"

function VerifiedDoc() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      fill="none"
      viewBox="0 0 100 100"
    >
      <path
        stroke="#F66B02"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
        d="M81 56.162l-15.5-5.707L50 56.162V77.09l15.5 11.414L81 77.09V56.162z"
      ></path>
      <path
        stroke="#001F79"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
        d="M74 45.333V12H19v75h26.666"
      ></path>
      <path
        stroke="#F66B02"
        strokeLinecap="round"
        strokeWidth="5"
        d="M29 28.667h35M29 42h16.667"
      ></path>
      <path
        stroke="#F66B02"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
        d="M59 66.75L64 73l8.333-10"
      ></path>
    </svg>
  )
}

export { VerifiedDoc }
