// MedicalLoanIcon
import React from "react"

function MedicalLoanIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      fill="none"
      viewBox="0 0 48 48"
    >
      <path
        fill="#F66B02"
        d="M32.405 22.574H28.375v-4.03a1.472 1.472 0 00-1.461-1.482h-5.818c-.812 0-1.471.66-1.471 1.472v4.03h-4.02a1.472 1.472 0 00-1.482 1.461v5.818c0 .813.66 1.471 1.472 1.471h4.03v4.02a1.472 1.472 0 001.462 1.482H26.893c.813 0 1.472-.66 1.472-1.472v-4.03h4.03a1.472 1.472 0 001.48-1.461v-5.808c0-.813-.658-1.471-1.471-1.471zm-.504 6.765h-4.513a.988.988 0 00-.988.988v4.513h-4.8v-4.513a.988.988 0 00-.988-.988H16.1v-4.79h4.513a.988.988 0 00.988-.988v-4.523h4.79v4.513c0 .546.442.988.988.988H31.9v4.8z"
      ></path>
      <path
        fill="#fff"
        d="M41.185 9.676h-8.138V6.199a2.874 2.874 0 00-3.022-2.963h-12.05A2.874 2.874 0 0014.953 6.2v3.457H6.815C3.675 9.656 0 11.444 0 16.471v21.481c0 5.028 3.674 6.815 6.815 6.815h34.37c3.14 0 6.815-1.787 6.815-6.815V16.491c0-5.027-3.664-6.815-6.815-6.815zM16.928 6.199c0-.286 0-1.056 1.057-1.056h12.04c1.056 0 1.056.76 1.056 1.056v3.457H16.928V6.2zm29.097 31.753c0 4.366-3.378 4.84-4.84 4.84H6.815c-1.452 0-4.84-.474-4.84-4.84V16.491c0-4.366 3.378-4.84 4.84-4.84h34.37c1.452 0 4.84.474 4.84 4.84v21.461z"
      ></path>
    </svg>
  )
}

export { MedicalLoanIcon }
