// ManageLoanIcon
import React from "react"

function ManageLoanIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      fill="none"
      viewBox="0 0 48 48"
    >
      <g clip-path="url(#clip0)">
        <path
          fill="#fff"
          d="M25.847 14.36l4.309-3.59v-.001l.003-.002a.717.717 0 00.04-.037l.008-.007a.763.763 0 00.046-.05l.005-.007a.696.696 0 00.034-.046l.012-.02c.008-.011.016-.023.022-.036l.014-.026a.732.732 0 00.063-.19.65.65 0 00.006-.037l.004-.029a.687.687 0 00.002-.047v-.016-.004a.687.687 0 00-.003-.069l-.001-.007a.643.643 0 00-.009-.059l-.007-.027c-.003-.013-.005-.025-.009-.037l-.01-.032-.012-.03a.649.649 0 00-.014-.034l-.014-.027a.653.653 0 00-.017-.033l-.009-.014-1.75-2.852a.713.713 0 00-.607-.34H20.047a.713.713 0 00-.608.34l-1.75 2.852-.008.014a.653.653 0 00-.031.06l-.014.033a.604.604 0 00-.012.031l-.01.032-.01.037-.006.027a.722.722 0 00-.01.059v.007a.718.718 0 00-.004.07v.019l.003.047.004.03a.755.755 0 00.03.132l.011.031.012.03.016.033.013.026.023.037.011.019.035.046.005.007c.014.017.03.034.046.05l.008.007a.715.715 0 00.04.037l.002.002 4.31 3.591c-8.437.923-15.024 8.091-15.024 16.77C7.129 40.433 14.697 48 23.999 48c9.304 0 16.872-7.568 16.872-16.871 0-8.679-6.587-15.847-15.024-16.77zm-1.134-.91v-2.518h3.02l-3.02 2.517zm2.434-5.37h.407l.875 1.426H25.72l1.426-1.426zm-2.017 0L24 9.21l-1.13-1.13h2.26zm-4.684 0h.407l1.426 1.426H19.57l.875-1.425zm-.18 2.852h3.021v2.517l-3.021-2.517zM24 46.575c-8.517 0-15.446-6.928-15.446-15.445S15.484 15.684 24 15.684c8.517 0 15.445 6.93 15.445 15.446 0 8.517-6.928 15.445-15.445 15.445z"
        ></path>
        <path
          fill="#F66B02"
          d="M36.918 25.672a14.047 14.047 0 00-5.08-6.168A13.94 13.94 0 0024 17.11a13.932 13.932 0 00-7.838 2.394 14.205 14.205 0 00-2.075 1.711 14.115 14.115 0 00-3.005 4.457A13.934 13.934 0 009.98 31.13c0 2.738.79 5.392 2.284 7.672a14.037 14.037 0 005.91 5.083 13.967 13.967 0 005.825 1.265h.003a13.977 13.977 0 008.45-2.832 14.135 14.135 0 002.143-2.006.714.714 0 00-1.076-.935h-.001a12.605 12.605 0 01-6.751 4.042c-.902.203-1.834.305-2.767.305a12.493 12.493 0 01-5.234-1.135h-.001a12.633 12.633 0 01-5.308-4.566 12.542 12.542 0 01-2.051-6.893 12.552 12.552 0 013.689-8.905A12.557 12.557 0 0124 18.535a12.55 12.55 0 018.905 3.689 12.55 12.55 0 013.689 8.905 12.515 12.515 0 01-1.538 6.036.71.71 0 00.624 1.055c.262 0 .501-.142.627-.37a13.927 13.927 0 001.712-6.72c0-1.893-.37-3.73-1.101-5.458zM24 0a.713.713 0 00-.713.713v2.851a.713.713 0 101.426 0V.713A.713.713 0 0024 0zM18.914 4.736l-1.426-2.47a.713.713 0 10-1.234.714l1.426 2.469a.712.712 0 101.234-.713zM31.485 2.006a.713.713 0 00-.973.26l-1.426 2.47a.713.713 0 101.235.713l1.425-2.47a.713.713 0 00-.26-.973z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H48V48H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export { ManageLoanIcon }
