import React from "react"

function ClockIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      fill="none"
      viewBox="0 0 100 100"
    >
      <circle
        cx="49.5"
        cy="49.5"
        r="38"
        stroke="#001F79"
        strokeWidth="5"
      ></circle>
      <path
        stroke="#F66B02"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
        d="M49 24v26h25"
      ></path>
    </svg>
  )
}

export { ClockIcon }
