// TravelLoanIcon
import React from "react"

function TravelLoanIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      fill="none"
      viewBox="0 0 48 48"
    >
      <path
        fill="#fff"
        d="M47.488 12.393c-.652-1.76-2.599-2.815-4.446-2.346L40.46 8.472a3.759 3.759 0 00-3.232-.318l-9.444 3.437L20.03.633a.753.753 0 00-.868-.272L14.938 1.9a.749.749 0 00-.448.96l4.356 11.983-9.39 3.417-5.044-5.641a.748.748 0 00-.815-.205l-2.82 1.026a.749.749 0 00-.448.96l4.103 11.276a.75.75 0 00.705.494h.01l15.892-.195a.734.734 0 00.247-.046l1.408-.514L26.8 36.691a.75.75 0 00.96.449l4.23-1.54a.749.749 0 00.49-.767l-1.046-12.597 13.815-5.031a3.766 3.766 0 002.466-3.532c.001-.426-.074-.854-.226-1.28zm-31.332-9.34l2.98-1.086 7.188 10.155-6.069 2.208-4.1-11.278zM4.046 20.227l-2.051-5.636 1.638-.595 4.306 4.815-3.893 1.416zm23.906 15.246l-5.13-14.095 6.83-2.486 1.286 15.495-2.985 1.086zm16.784-19.677l-13.431 4.89-.24-2.896a.747.747 0 00-1.004-.644l-8.457 3.078a.751.751 0 00-.448.961l1.026 2.819-1.288.47-15.234.188-1.1-3.025L37.74 9.565a2.26 2.26 0 011.942.19l.718.437-2.202.802.513 1.41 3.329-1.211.508.31a.75.75 0 00.64.066 2.28 2.28 0 012.89 1.337c.091.255.138.513.138.768-.001.948-.596 1.8-1.48 2.123z"
      ></path>
      <path
        fill="#F66B02"
        d="M33.274 12.783l1.409-.512.513 1.41-1.41.512-.512-1.41zM30.455 13.81l1.409-.512.512 1.41-1.409.512-.512-1.41zM27.636 14.838l1.409-.513.512 1.41-1.408.512-.513-1.41zM24.816 15.865l1.409-.512.513 1.41-1.41.512-.512-1.41zM21.996 16.893l1.41-.513.512 1.41-1.409.512-.513-1.41zM19.177 17.92l1.408-.513.513 1.41-1.409.512-.512-1.41zM16.357 18.945l1.41-.512.512 1.41-1.409.512-.513-1.41zM13.531 19.98l1.41-.512.513 1.41-1.41.512-.513-1.41zM10.71 21.008l1.41-.513.513 1.41-1.41.512-.512-1.41zM14.25 33h4.638c.31.87 1.135 1.5 2.112 1.5 1.24 0 2.25-1.01 2.25-2.25S22.24 30 21 30c-.977 0-1.802.63-2.112 1.5H14.25a4.504 4.504 0 00-4.5 4.5c0 2.482 2.018 4.5 4.5 4.5H27V39H14.25c-1.655 0-3-1.346-3-3s1.345-3 3-3zM21 31.5a.75.75 0 11-.002 1.502A.75.75 0 0121 31.5zM28.5 39H30v1.5h-1.5V39z"
      ></path>
      <path
        fill="#F66B02"
        d="M37.875 39H31.5v1.5h6.375c1.034 0 1.875.842 1.875 1.875s-.84 1.875-1.875 1.875H14.25v1.5h23.625a3.38 3.38 0 003.375-3.375A3.38 3.38 0 0037.875 39zM3.75 42.75c-1.24 0-2.25 1.01-2.25 2.25s1.01 2.25 2.25 2.25c.976 0 1.801-.63 2.112-1.5h6.888v-1.5H5.862a2.247 2.247 0 00-2.112-1.5zm0 3a.75.75 0 11.002-1.502.75.75 0 01-.002 1.502z"
      ></path>
    </svg>
  )
}

export { TravelLoanIcon }
