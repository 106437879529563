import React, { FC } from "react"
import {
  Center,
  Stack,
  Switcher,
  Text,
  PrimaryButton,
  Cluster,
  Box,
  Frame,
  Grid,
} from "../common"
import { Helmet } from "react-helmet"
import Slider from "react-slick"
import { VerifiedDoc } from "../common/icons/VerfiedDoc"
import { ClockIcon } from "../common/icons/Clock"
import { MoneyIcon } from "../common/icons/Money"
import { UserIcon } from "../common/icons/User"
import { QuotesIcon } from "../common/icons/Quotes"
import { PlayStoreIcon } from "../common/icons/PlayStore"
import { AppStoreIcon } from "../common/icons/AppStore"
import { Form, Input } from "antd"
import { css } from "@emotion/core"
import { Layout } from "../components/Layout"
import { MOBILE_NO_REGEX } from "../common/utils"
import { useContactUs } from "../components/useContactUs"
import { Link } from "gatsby"
import { ManageLoanIcon } from "../common/icons/ManageLoanIcon"
import { MedicalLoanIcon } from "../common/icons/MedicalLoanIcon"
import { LaptopLoanIcon } from "../common/icons/LaptopLoanIcon"
import { TravelLoanIcon } from "../common/icons/TravelLoanIcon"
import { MobileLoanIcon } from "../common/icons/MobileLoanIcon"
import { HomeRenovationIcon } from "../common/icons/HomeRenovationIcon"
import { ConsumerDurableIcon } from "../common/icons/ConsumerDurableIcon"
import { DebtConsolidationIcon } from "../common/icons/DebtConsolidationIcon"
import { ZHelmet } from "../components/ZHelmet"
import {
  PRODUCTS as PRODUCT_DETAILS,
  ProductPageType,
} from "../data/productsData"

const WHY_CHOOSE_US = [
  {
    icon: <VerifiedDoc />,
    title: "Easy Process",
    content:
      "Applying for loan is simple, quick and hassle-free with Zavron Finserv",
  },
  {
    icon: <ClockIcon />,
    title: "Fast Loan",
    content: "Our loan sanction and disbursal takes just two days’ time.",
  },
  {
    icon: <MoneyIcon />,
    title: "Funding Capacity",
    content:
      "We provide you with a loan of INR 50k to INR 15 lacs as per your eligibility",
  },
  {
    icon: <UserIcon />,
    title: "Pre-approved Offers",
    content:
      "Our customers are family and we have exceptional loan offers for the existing members.",
  },
]

const PRODUCTS = [
  {
    num: "01",
    title: "Personal Loan",
    link: "/products/personal-loan",
    content:
      "Get loans for all your personal needs from emergencies, home renovations to planning a vacation instantly",
  },
  {
    num: "02",
    title: "Used Car Loan",
    link: "/products/used-car-loan",
    content:
      "Shortage of funds for a used car? Not a problem, we have flexible EMI options for you",
  },
  {
    num: "03",
    title: "Two-wheeler Loan",
    link: "/products/two-wheeler-loan",
    content:
      "Buying your favorite two-wheeler becomes easier with Zavron Finserv’s Vehicle Loan at attractive interest rates",
  },
  {
    num: "04",
    title: "Business Loan",
    link: "/products/business-loan",
    content:
      "Plan the next expansion of your business with our easy and flexible loan policies",
  },
]

const TESTIMONIALS = [
  {
    message: `‘I had a seamless experience with Zavron. The process was smooth and quick.’`,
    name: "Naresh Thawani",
    title: "Employee",
  },
  {
    message: `‘They have an easy process. I got a loan in just 24 hours. Very easy documentation process.’`,
    name: "Kushal Singh",
    title: "Business Owner",
  },
  {
    message: `‘I am happy with Zavron. I needed urgent money and my loan was approved quickly without any hassle.’`,
    name: "Neelam Verma",
    title: "HR",
  },
  {
    message: `‘My loan process went swiftly. Their relationship representative was kind enough to help me throughout.’`,
    name: "Gaurav Sharma",
    title: "Business Owner",
  },
]

export default function Home() {
  return (
    <Layout>
      <ZHelmet
        title="Instant | Personal Loan | Business Loan | NBFC | Fintech | India"
        description="Instant Personal Loan and Business loan as well as Used Car Loan or Two Wheeler Loan with fast and easy approval within your reach to apply now with Zavron Finserv"
      />
      <Stack space="var(--s5)">
        <Landing />
        <WhyUs />
        <Products />
        <Testimonials />
        {/* <AppAdvertisement /> */}
        <ContactUs />
      </Stack>
    </Layout>
  )
}

const Landing: FC = props => (
  <Box>
    <Center gutters="var(--s2)" maxWidth="var(--max-width)">
      <Switcher threshold="var(--min-width)" space="var(--s-2)" max={2}>
        <Stack
          space="var(--s0)"
          justify="center"
          css={css`
            @media screen and (min-width: 900px) {
              padding-right: var(--s5);
            }
          `}
        >
          <Text as="h1" variant="h1">
            A Step Closer To Your Dreams
          </Text>
          <Text variant="h5" color="light">
            Zavron Finserv provides Easy, Fast and Flexible loans which will
            help you meet your financial needs. Get instant Personal Loans,
            Business Loans, Used Car Loans and Two-Wheeler Loans with ease.
            Required loan amount can vary between INR 50,000 to INR 15 Lacs. Our
            focus is to provide enhanced customer satisfaction and hassle-free
            user experience.
          </Text>
          <Text variant="h5" color="light">
            We are continuously working on providing ease of loan to our
            customers and give them a stellar experience. As per company’s
            internal policies eligibility criteria for any loan and other
            important components are subjected to change.
          </Text>
          <Cluster space="var(--s1)" justify="flex-start">
            <PrimaryButton
              onClick={() => {
                const targetElement = document.getElementById("home-why-us")
                const fromTop = targetElement.getBoundingClientRect().y
                window.scrollTo({
                  top: fromTop - 90,
                  behavior: "smooth",
                })
              }}
              style={{ width: 150 }}
            >
              Learn More
            </PrimaryButton>
          </Cluster>
        </Stack>
        <Box style={{ flexGrow: 1 }}>
          <Frame numerator={6} denominator={5}>
            <img
              src="https://res.cloudinary.com/dhoiqmk4x/image/upload/c_scale,f_auto,w_800/v1597798964/Group_170_ttuj6x.png"
              alt=""
            />
          </Frame>
        </Box>
      </Switcher>
    </Center>
  </Box>
)
const Products: FC = props => (
  <Box
    style={{ margin: "-var(--s2)" }}
    css={css`
      padding-top: var(--s4) !important;
      padding-bottom: var(--s4) !important;
      background-image: url("https://res.cloudinary.com/dhoiqmk4x/image/upload/c_scale,f_auto,w_1400/v1597800020/products-background_p1q9pa.png");
    `}
  >
    <Center gutters="var(--s2)" maxWidth="var(--max-width)">
      <Stack space="var(--s3)">
        <Text
          style={{
            textTransform: "uppercase",
            color: "var(--secondary-color)",
          }}
          variant="h2"
          css={css`
            @media screen and (max-width: 600px) {
              text-align: center;
            }
          `}
        >
          How can Zavron FINSERV change <br /> your life?
        </Text>
        <ProductItem item={PRODUCTS[0]} />
        <PersonalLoanProductDetails />
        <Switcher max={3} threshold="var(--max-width)" space="var(--s2)">
          <ProductItem item={PRODUCTS[1]} />
          <ProductItem item={PRODUCTS[2]} />
          <ProductItem item={PRODUCTS[3]} />
        </Switcher>
      </Stack>
    </Center>
  </Box>
)

const getProductLink = (product: ProductPageType) => `https://zavronfinserv.com/products/${product.slug}`
const PersonalLoanProductDetails: FC = props => {
  const details = [
    {
      icon: <ManageLoanIcon />,
      title: "Marriage Loan",
      link: getProductLink(PRODUCT_DETAILS.marriage_loan),
    },
    {
      icon: <MedicalLoanIcon />,
      title: "Medical Loan",
      link: getProductLink(PRODUCT_DETAILS.medical_loan),
    },
    {
      icon: <LaptopLoanIcon />,
      title: "Laptop Loan",
      link: getProductLink(PRODUCT_DETAILS.laptop_loan),
    },
    {
      icon: <TravelLoanIcon />,
      title: "Travel Loan",
      link: getProductLink(PRODUCT_DETAILS.travel_loan),
    },
    {
      icon: <MobileLoanIcon />,
      title: "Mobile Loan",
      link: getProductLink(PRODUCT_DETAILS.mobile_loan),
    },
    {
      icon: <HomeRenovationIcon />,
      title: "Home Renovation Loan",
      link: getProductLink(PRODUCT_DETAILS.home_renovation_loan),
    },
    {
      icon: <ConsumerDurableIcon />,
      title: "Consumer Durable Loan",
      link: getProductLink(PRODUCT_DETAILS.consumer_durable_loan),
    },
    {
      icon: <DebtConsolidationIcon />,
      title: "Debt Consolidation Loan",
      link: getProductLink(PRODUCT_DETAILS.debit_consolidation_loan),
    },
  ]
  return (
    <Grid
      style={{ textAlign: "center", marginBottom: "var(--s0)" }}
      gap="var(--s1)"
      min="250px"
    >
      {details.map(detail => (
        <Stack space="var(--s1)" style={{ marginBottom: "var(--s1)" }}>
          <Link to={detail.link} target="_blank">
            <Box>{detail.icon}</Box>
            <Text variant="h4" style={{ color: "white" }}>
              {detail.title}
            </Text>
          </Link>
        </Stack>
      ))}
    </Grid>
  )
}

const ProductItem: FC<{
  item: { num: string; title: string; content: string; link: string }
}> = props => {
  const { item } = props
  return (
    <Cluster space="var(--s0)" align="flex-start">
      <Link to={item.link}>
        <Text
          style={{
            color: "var(--secondary-color)",
            fontFamily: "var(--body-font-family)",
          }}
          variant="h2"
        >
          {item.num}
        </Text>
      </Link>
      <Stack space="var(--s0)" style={{ maxWidth: 280 }}>
        <Link to={item.link}>
          <Text
            as="h2"
            style={{
              color: "white",
              fontFamily: "var(--heading-font-regular)",
            }}
            variant="h1"
          >
            {item.title}
          </Text>
        </Link>
        <Text style={{ color: "white" }} variant="p">
          {item.content}
        </Text>
      </Stack>
    </Cluster>
  )
}

const Testimonials: FC = props => (
  <Box>
    <Center gutters="var(--s2)" maxWidth="var(--max-width)">
      <Stack space="var(--s3)">
        <Text
          style={{ opacity: 0.2, textTransform: "uppercase" }}
          variant="h2"
          color="light"
          css={css`
            @media screen and (max-width: 600px) {
              text-align: center;
            }
          `}
        >
          A Few Words From Our Happy Customers
        </Text>
        <Helmet>
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
          />
        </Helmet>

        <Switcher max={3} threshold="var(--min-width)" space="var(--s0)">
          <Box
            css={css`
              @media screen and (max-width: 600px) {
                display: none;
              }
            `}
          >
            <Frame numerator={1} denominator={1}>
              <img
                src="https://res.cloudinary.com/dhoiqmk4x/image/upload/c_scale,f_auto,w_700/v1595626760/Website/slider-left_pq5qhm.png"
                alt=""
              />
            </Frame>
          </Box>
          <Box
            css={css`
              max-width: 35rem;
              @media screen and (max-width: 600px) {
                max-width: 100%;
              }
            `}
          >
            <Slider dots>
              {TESTIMONIALS.map(item => (
                <Center maxWidth="540px" andText>
                  <Stack align="center" space="var(--s1)">
                    <QuotesIcon />
                    <Text style={{ color: "var(--black-color)" }} variant="h3">
                      {item.message}
                    </Text>
                    <Stack style={{ marginBottom: 20 }}>
                      <Text variant="h4">{item.name}</Text>
                      <Text variant="p">{item.title}</Text>
                    </Stack>
                  </Stack>
                </Center>
              ))}
            </Slider>
          </Box>
          <Box
            css={css`
              @media screen and (max-width: 600px) {
                display: none;
              }
            `}
          >
            <Frame numerator={1} denominator={1}>
              <img
                src="https://res.cloudinary.com/dhoiqmk4x/image/upload/c_scale,f_auto,w_700/v1595626763/Website/slider-right_z5p49g.png"
                alt=""
              />
            </Frame>
          </Box>
        </Switcher>
      </Stack>
    </Center>
  </Box>
)
const AppAdvertisement: FC = props => (
  <Box>
    <Center gutters="var(--s2)" maxWidth="var(--max-width)">
      <Switcher threshold="var(--min-width)" space="var(--s2)" max={2}>
        <Box
          css={css`
            @media screen and (min-width: 900px) {
              padding-right: var(--s5);
            }
          `}
        >
          <Frame numerator={29} denominator={20}>
            <img
              src="https://res.cloudinary.com/dhoiqmk4x/image/upload/c_scale,f_auto,w_1200/v1595677817/Website/phone_x2fsky.png"
              alt="landing"
            />
          </Frame>
        </Box>
        <Stack space="var(--s2)" justify="center">
          <Stack space="var(--s1)">
            <Text variant="h1">Get Quick Loans in Just A Few Taps</Text>
            <Text
              variant="h4"
              style={{ fontFamily: "var(--body-font-family)" }}
            >
              t quick online loan approvals on ZCash hassle-free and
              conveniently on our mobile app.
            </Text>
          </Stack>

          <Cluster space="var(--s1)" justify="flex-start">
            <PlayStoreIcon />
            <AppStoreIcon />
          </Cluster>
        </Stack>
      </Switcher>
    </Center>
  </Box>
)
const ContactUs: FC = props => {
  const [form] = Form.useForm()
  const { loading, sendData: handleFinish } = useContactUs(() => {
    form.resetFields()
  })
  return (
    <Box padding="var(--s0) 0">
      <Center gutters="var(--s2)" maxWidth="var(--max-width)">
        <Stack space="var(--s3)">
          <Text variant="h1">Connect With Us</Text>
          <Switcher threshold="var(--min-width)" space="var(--s4)" max={2}>
            <Box>
              <Form form={form} onFinish={handleFinish} layout="vertical">
                <Form.Item
                  label="Full Name"
                  name="fullName"
                  rules={[
                    { required: true, message: "Please enter a valid name" },
                  ]}
                >
                  <Input style={{ borderRadius: 5 }} size="large" />
                </Form.Item>
                <Form.Item
                  label="Contact Number"
                  name="contactNumber"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a valid contact number",
                    },
                    {
                      validator: (_, value, callback) => {
                        if (value && MOBILE_NO_REGEX.test(value)) {
                          callback()
                        } else {
                          callback("Please enter a valid contact number")
                        }
                      },
                    },
                  ]}
                >
                  <Input style={{ borderRadius: 5 }} size="large" />
                </Form.Item>
                <Form.Item label="Your Message" name="message">
                  <Input.TextArea rows={8} style={{ borderRadius: 5 }} />
                </Form.Item>
                <Form.Item>
                  <PrimaryButton
                    loading={loading}
                    style={{ width: "100%" }}
                    htmlType="submit"
                  >
                    Send
                  </PrimaryButton>
                </Form.Item>
              </Form>
            </Box>
            <Stack
              css={css`
                @media screen and (min-width: 900px) {
                  padding-left: var(--s5);
                }
              `}
              space="var(--s3)"
              justify="center"
            >
              <Stack space="var(--s-1)">
                <Text variant="h4">Address</Text>
                <Stack space="var(--s-5)">
                  <Text color="light" variant="p">
                    125, Zavron Finance Pvt Ltd., Near Memorial Hospital,
                  </Text>
                  <Text color="light" variant="p">
                    Maharajbaugh Road, Sitabuldi,
                  </Text>
                  <Text color="light" variant="p">
                    Nagpur -440012
                  </Text>
                </Stack>
              </Stack>

              <Stack space="var(--s-1)">
                <Text variant="h4">Support</Text>
                <Stack space="var(--s-5)">
                  <a href="tel: +91-8447723591">
                    <Text color="light" variant="p">
                     +91-8447723591
                    </Text>
                  </a>
                  <a href="mailto:info@zavronfinserv.com">
                    <Text color="light" variant="p">
                      info@zavronfinserv.com
                    </Text>
                  </a>
                </Stack>
              </Stack>

              {/* <Stack space="var(--s-1)">
                <Text variant="h4">Timing</Text>
                <Stack space="var(--s-5)">
                  <Text color="light" variant="p">
                    9 AM - 9 PM
                  </Text>
                  <Text color="light" variant="p">
                    Monday - Friday
                  </Text>
                </Stack>
              </Stack> */}
            </Stack>
          </Switcher>
        </Stack>
      </Center>
    </Box>
  )
}
const WhyUs: FC = props => {
  return (
    <Box id="home-why-us">
      <Center gutters="var(--s2)" maxWidth="var(--max-width)">
        <Stack space="var(--s3)">
          <Text
            style={{ opacity: 0.2, textTransform: "uppercase" }}
            variant="h2"
            color="light"
            css={css`
              @media screen and (max-width: 600px) {
                text-align: center;
              }
            `}
          >
            Why Choose Us?
          </Text>
          <Switcher max={4} threshold="var(--min-width)" space="var(--s1)">
            {WHY_CHOOSE_US.map(item => (
              <Stack space="var(--s1)" align="center">
                {item.icon}
                <Stack space="var(--s-2)" align="center">
                  <Text variant="h3">{item.title}</Text>
                  <Text
                    color="light"
                    style={{ textAlign: "center" }}
                    variant="p"
                  >
                    {item.content}
                  </Text>
                </Stack>
              </Stack>
            ))}
          </Switcher>
        </Stack>
      </Center>
    </Box>
  )
}
